<template>
  <v-container>
    <v-sheet v-show="initialLoad"
             :color="`${$vuetify.theme.dark ? '' : 'grey lighten-4'}`"
             class="px-3 pt-3 pb-12">
      <v-responsive class="mx-auto">
        <v-skeleton-loader ref="skeleton" class="mx-auto" type="table"></v-skeleton-loader>
      </v-responsive>
    </v-sheet>
    <v-card v-show="!initialLoad" flat >
      <v-card-title>SALi Parcel Manifest for Scanned Addresses</v-card-title>
      <v-card-text>
        <v-data-table
            :footer-props="{showFirstLastPage: true, itemsPerPageOptions: [10, 25, 50, 100, 250]}"
            :headers="headers"
            :height="800"
            :items="parcels.images"
            :items-per-page="20"
            :loading="$apollo.queries.parcels.loading"
            :options.sync="options"
            :search="search"
            :server-items-length="total.page.count"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            calculate-widths
            class="elevation-0"
            fixed-header
            loading-text="Fetching parcels..."
            multi-sort
            no-data-text="No available parcels to display!"
            no-results-text="No available parcels to display!"
        >
          <template v-slot:body="{items}">
            <tbody>
            <tr v-for="item in items" :key="item.tif"
                :class="`${$store.state.selectedData && item.tif === $store.state.selectedData.value.tif ? $vuetify.theme.dark ? 'grey darken-3 ': 'grey lighten-2' : ''}`"
                @click="select(item)">
              <td class="font-weight-light">
                {{ item.tif }}
              </td>
              <td>
                <v-chip v-for="(address, index) in getAddresses(item.pages, 'detected_address')"
                        :key="`parcel-entries-${index}`" :disabled="address ==='No detected address'" small>
                  {{ address }}
                </v-chip>
              </td>
              <td>
                <v-chip v-for="(address, index) in getAddresses(item.pages, 'formatted_address')"
                        :key="`parcel-entries-${index}`" :disabled="address ==='No detected address'" dark small>
                  {{ address }}
                </v-chip>
              </td>
              <!--          <td class="font-weight-light">-->
              <!--            {{ item.recipient.name[0] || 'N/A' }}-->
              <!--          </td>-->
              <!--          <td class="font-weight-light">-->
              <!--            {{ item.recipient.contact[0] || 'N/A' }}-->
              <!--          </td>-->
              <!--          <td class="text-right">-->
              <!--            <span class="caption">{{-->
              <!--                item.manifest_fr.scan_time ? item.manifest_fr.scan_time.toFixed(5) : 'n/a'-->
              <!--              }}</span>-->
              <!--          </td>-->
              <!--          <td>-->
              <!--            <v-chip class="teal" dark>-->
              <!--              {{ item.created_by }}-->
              <!--            </v-chip>-->
              <!--          </td>-->
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
import {format} from 'timeago.js'
import gql from 'graphql-tag'
import firebase from "firebase/app"

const storage = firebase.storage()

export default {
  apollo: {
    parcels: {
      skip: true,
      query: gql`
        query parcels($limit: Int!, $offset: Int!, $where: view_parcels_bool_exp){
          images: view_parcels(limit: $limit, offset: $offset, where: $where) {
            tif
            pages
          }
        }
      `,
      variables() {
        return {
          limit: this.options.itemsPerPage ?? 0,
          offset: (this.options.itemsPerPage ?? 0) * ((this.options.page ?? 0) - 1)
        }
      },
      update(data) {
        return {
          ...data
        }
      }
    },
    total: {
      query: gql`
        query {
          total: view_parcels_aggregate {
            page: aggregate {
              count
            }
          }
        }
      `,
      update(data) {
        this.initialLoad = false
        return data.total
      }
    },

  },
  data() {
    return {
      page: 1,
      headers: [
        {text: 'IMAGE', value: 'image',},
        {text: 'SCANNED ADDRESS', value: 'recipient.raw_addresses'},
        {text: 'FORMATTED ADDRESS', value: 'recipient.addresses'},
        // {text: 'RECIPIENT CONTACT', value: 'recipient.contact'},
        // {text: 'DATE CREATED', value: 'date_created'},
      ],
      loading: false,
      options: {},
      sortBy: ['tif'],
      sortDesc: [false],
      initialLoad: true,
      parcels: {
        images: []
      },
      total: {
        page: {
          count: 0
        }
      }
    }
  },
  computed: {
    search: {
      get() {
        return this.$store.state.search
      },
      set(val) {
        this.$store.commit('setState', ['search', val])
      }
    }
  },
  watch: {
    options: {
      handler: _debounce(function () {
        this.optionSearchHandler()
      }, 500),
      deep: true
    },
    search: {
      handler: _debounce(function () {
        this.optionSearchHandler()
      }, 500),
      deep: true
    }
  },
  created() {
    // this.$apollo.queries.parcels.skip = false
  },
  methods: {
    getAddresses(items, key) {
      const addresses = items.reduce((acc, cv) => {
        if (cv[key] !== null && cv[key].length > 0) {
          acc = [...acc, ...cv[key]]
        }
        return acc
      }, [])
      return addresses.length ? new Set(addresses) : ['No detected address']
    },
    async optionSearchHandler() {
      this.loading = true
      await this.$apollo.queries.parcels.setVariables({
        limit: this.options.itemsPerPage,
        offset: this.options.itemsPerPage * (this.options.page - 1),
        where: {
          tif: {
            _ilike: `%${this.search}%`
          }
        }
      })
      this.$apollo.queries.parcels.skip = false
      this.loading = false
    },
    async select(item) {
      let data = {
        value: item,
        type: this.type
      }

      if (this.$store.state.selectedData !== data) {
        this.$store.commit('setSelectedData', {value: item, type: 'manifest'})
        Promise.all(
            item.pages.reduce((a, v) => {
              const url = storage.refFromURL(v.image).getDownloadURL()
              return [...a, url]
            }, [])
        ).then(urls => {
          this.$store.commit('setSelectedImages', urls)
        })
      }
    },
    format
  },
  beforeMount() {

    // Show app bar and left nav bar
    this.$store.commit('setState', ['leftNav', false])
    this.$store.commit('setState', ['appBar', true])
    this.$store.commit('initStore')

  }
}
</script>

<style scoped>

</style>
